<template>
	<div>
		<CustomTable
			ref="table"
			id_table="liste_courrier_type_fiche"
			:busy.sync="table_busy"
			primaryKey="model_id"
			:items="courrier_type"
		/>
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from "EventBus";
    import ModelMixin from "@/mixins/Model.js";
    import Navigation from "@/mixins/Navigation.js";
	import Shutter from "@/mixins/Shutter.js"
    import ShutterHorse from '@/mixins/shutters-manager/HorseCourrier.js'

	export default {
		name: 'CourrierTypeHorse',
		mixins: [ModelMixin, Navigation, Shutter, ShutterHorse],
		props: ['horse_id'],
		data () {
			return {
                table_busy: false,
                courrier_type: [],
                events_tab: {
                    'TableAction::goToAddCourrier': this.openAjout,
                    'TableAction::goToEditCourrier': (model) => {
		            	this.editModel(model.model[0].model_id, model.model[0].type.modeltype_template) 
		            },
		            'TableAction::goToPdfCourrier': (model) => {
		            	this.goToPrintCourrier(model.model[0].model_id, model.model[0].model_label)
		            },
		            'TableAction::goToDeleteCourrier': (models_ids) => {
		            	this.goToDeleteCourrier(models_ids)
		            }
                }
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
                this.table_busy = true
                this.courrier_type = await this.loadAllModel(this.horse_id, ['horse_envoie_infos_generated'])
                this.table_busy = false
			},

			openAjout() {
				this.setupCourrierTypeAjout(this.horse_id)
			},

			editModel(model_id, modeltype_template) {
				this.setupCourrierTypeEdit(this.horse_id, model_id, modeltype_template)
			},

			async goToPrintCourrier(model_id, model_name) {
				let result = await this.printCourrier(model_id, model_name)
                if(result == null){
                	this.failureToast('monte.print_error')					
                }
                EventBus.$emit("TableAction::stopSpin")
			},

			async goToDeleteCourrier(models_ids) {
				await this.deleteModel(models_ids);
				EventBus.$emit('TableAction::stopSpin')
				this.$refs.table.unselectAll()
				this.init_component()
			}
		},

		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			Edit : () => import('@/components/Model/Edit')
		}
	}

</script>